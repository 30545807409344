import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import '../styles/tabs.css'
import researchProj from '../data/researchProjects'
import aeroProjects from '../data/aerospaceProjects'
import ProjectBox from '../components/Projects/ProjectBox'
import { ProjectsWrapper, ProjectsSection } from '../styles/projectsStyle.js'
import Layout from '../components/layout'

const Projects = () => (
  <Layout>
  <ProjectsWrapper>
    <Tabs>
      <TabList>
        <Tab>Aerospace Applications</Tab>
        <Tab>Research</Tab>
      </TabList>

      <TabPanel>
        <ProjectsSection>
          {
            aeroProjects.map(item => (<ProjectBox key={item.id} info={item} />))
          }
        </ProjectsSection>
      </TabPanel>
      <TabPanel>
        <ProjectsSection>
          {
            researchProj.map(item => (<ProjectBox key={item.id} info={item} />))
          }
        </ProjectsSection>
      </TabPanel>
    </Tabs>
  </ProjectsWrapper>
  </Layout>
)

export default Projects
