import pregnancy from '../assets/images/projects/pregnancy.svg'
import like from '../assets/images/projects/social.svg'
import dare from '../assets/images/projects/dare.svg'
import journey from '../assets/images/projects/journey.svg'
import website from '../assets/images/projects/website.svg'
import aware from '../assets/images/projects/aware.svg'
import email from '../assets/images/projects/email.svg'
import vision from '../assets/images/projects/vision.svg'
import search from '../assets/images/projects/search.svg'

export default [
    {
        id: 5,
        title: "Modeling and Control of an Active Dihedral Fixed-Wing Unmanned Aircraft",
        // icon: ,
        // description: "Dare is a feature on the Zoojoobe platform developed in ReactJS that primarily focuses on the competitive behaviour of the users. Two users compete with each other to complete a particular healthy task. Meanwhile other users within the corporate can motivate the user using the cheer function.",
        githubPath: null,
        demoPath: null,
        pdfPath: null,
        year: "2022",
        // techUsed: "React, Material-UI"
    },
    {
        id: 4,
        title: "Wedge Slamming - FEA Analysis",
        // icon: journey,
        // description: "Journey feature on the Zoojoobe platform is a set of curated activities that are a set of micro-tasks that a user has to form to achieve a bigger goal like forming a complex habit. Journey feature has multiple levels which changes based on user activity. The whole task list is customised based on user activity.",
        githubPath: null,
        demoPath: null,
        pdfPath: null,
        year: "2019",
        // techUsed: "React, Material-UI"
    },
    {
        id: 3,
        title: "MTConnect",
        // icon: pregnancy,
        // description: "Maternity module was primarily designed for the women within a corporate. The feature takes into account the user's due date based on which it recommends articles and relevant mindfulness tracks. This feature helps them stay healthy throughout the process.",
        githubPath: null,
        demoPath: null,
        pdfPath: null,
        year: "2018",
        // techUsed: "React, Material-UI, React-Sound-Manager"
    },
    {
        id: 2,
        title: "UAV with Active Dihdral",
        // icon: website,
        // description: "Zoojoobe is a game based social wellness platform that unites the workforce to form healthier habits. The platform leverages on the tremendous power of the trusted social network at our workplace to motivate employees to form healthier habits. Developed the sales website on wordpress.",
        githubPath: null,
        demoPath: null,
        pdfPath: null,
        year: "2017",
        // techUsed: "HTML, CSS, jquery"
    },
    {
        id: 1,
        title: "CNC Tutorials",
        // icon: aware,
        description: "Find CNC tutorials here that use Fusion 360 and Easel by Inventables.",
        githubPath: "https://www.youtube.com/playlist?list=PLmmhVNnGQx_Nh9E6MIetSD7TX8h6TlyHY",
        demoPath: null,
        pdfPath: null,
        year: "2019",
        // techUsed: "React, Material-UI, SASS"
    },

];
