import antik from '../assets/images/projects/antik.svg'
import quiz from '../assets/images/projects/quiz.svg'

export default [
    {
        id: 1,
        title: "High Capacity Short Range Transport Air Vehicle",
        // icon: antik,
        description: "The Senior Design project I had the oppurtunity to be apart of as the Chief Engineer was selected as the 2nd place winner for the yearly AIAA Undergraduate Design Competition. The project was to design a high capacity short range transport aircraft to fill a major hole in the current market. Our aircraft was an innovative and competitive twist on a standard commercial airliner.",
        githubPath: null,
        demoPath: "../airvehicle",
        pdfPath: null,
        year: "2019 - 2020",
        // techUsed: "Python, Django, CSS Bootstrap"
    },
    {
      id: 2,
      title: "Stability and Control Analysis for Design Build Fly at Virginia Tech",
      description: "Design Build Fly is a student design team based out of the Virginia Tech Ware Lab. Each year we competed against other schools to design the best RC aircraft based on a provided RFP. While leading the stability and control team, we designed, tested, and implemented the empenage and control surfaces for the aircraft, allowing for a stable and controlled flight.",
      // icon: quiz,
      githubPath: null,
      demoPath: null,
      pdfPath: "../dbf",
      year: "2019-2020",
      // techUsed: "C"
    },
    {
      id: 3,
      title: "VTFly",
      description: "VTFly is a software designed to help Virginia Tech Design Build Fly have an advantage in the design phase of the competition by implementing Multi-Disciplinary Optimization (MDO). The application allows for DBF members to instantiate constraints (RFP dependent) and complete simluations of the theoretical aircraft. With a wide range of aircraft simulated, multi-dimensional plots can be generated to determine the best performing aircraft.",
      // icon: quiz,
      githubPath: null,
      demoPath: null,
      pdfPath: null,
      year: "2019-2020",
      // techUsed: "Java applets"
    }
]
